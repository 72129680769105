.orion_button,
.orion_button:active,
.orion_button:hover {
  user-select: none;
  border-radius: 5px;
  text-transform: none;
  letter-spacing: 0;
}

.orion_danger,
.orion_danger:active,
.orion_danger:hover {
  background-color: #f44336;
}

.orion_primary,
.orion_primary:active,
.orion_primary:hover {
  background-color: #2866ff;
}
