:local(.container) {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  background: #09d261;
  padding: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  composes: buttonShadow from global;
}

:local(.container) img {
  height: 30px;
  width: 30px;
  color: #fff;
}
