:local(.container) {
  padding: 20px;
}

:local(.label) {
  font-weight: bold;
}

:local(.description) {
  composes: description from global;
}

:local(.icon) {
  position: relative;
  left: -3px;
}
