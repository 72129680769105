.react-contextmenu {
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  border-radius: 5px;
  overflow: hidden;
}

.react-contextmenu-item {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: left;
  user-select: none;
  outline: none;
}

.react-contextmenu-item--selected {
  background: #2866ff;
  color: #fff;
}

.react-contextmenu-item--divider {
  padding: 0;
  height: 2px;
  background: #ddd;
}
