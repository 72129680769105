:local(.notFoundContainer) {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:local(.title) {
  font-family: 'Bould', sans-serif;
  font-size: 32px;
  line-height: 40px;
  width: 80%;
  text-align: center;
  color: #111111;
}

:local(.subTitle) {
  font-family: 'Bould', sans-serif;
  font-size: 18px;
  line-height: 40px;
  width: 80%;
  text-align: center;
  color: #111111;
}
