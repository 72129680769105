:local(.container) {
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 500ms cubic-bezier(0.9, 0, 0.1, 1);
}

:local(.container_entering) {
  background-color: rgba(0, 0, 0, 0);
}

:local(.container_entered) {
  background-color: rgba(0, 0, 0, 0.9);
}

:local(.container_exiting) {
  background-color: rgba(0, 0, 0, 0);
}

:local(.dialog) {
  position: relative;
  background-color: #fff;
  box-shadow: 0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  transition: all 500ms cubic-bezier(0.9, 0, 0.1, 1);
  position: relative;
  max-height: 90%;
  margin: 10px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-width: 350px;
}

:local(.dialog_entering) {
  opacity: 0;
  top: -60%;
}

:local(.dialog_entered) {
  opacity: 1;
  top: 0;
}

:local(.dialog_exiting) {
  opacity: 0;
  top: 100%;
}

:local(.content) {
  padding: 20px;
}

:local(.title) {
  font-size: 16px;
}

:local(.description) {
  color: #999;
  font-size: 14px;
}

:local(.form) {
  padding: 20px 0;
}

:local(.close) {
  position: absolute;
  right: 10px;
  top: 10px;
}

:local(.close) .os_iconButton {
  color: #ccc;
}
